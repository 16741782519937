@import './base/bootstrap.scss';
@import './base/bootstrap-extended.scss';
@import './base/colors.scss';
@import './base/components.scss';

// Themes
@import './base/themes/dark-layout.scss';
@import './base/themes/bordered-layout.scss';
@import './base/themes/semi-dark-layout.scss';
// @import './base/custom-rtl.scss';

// React Specific
@import 'react/index';

.loader-component {
    background-color: rgb(248, 248, 248, 60%);
    position: fixed;
    z-index: 1000000;
}

.dark-layout {
    .loader-component {
        background-color: rgb(22, 29, 49, 60%);
    }
}

.my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -30px; /* gutter size offset */
    width: auto;
}
.my-masonry-grid_column {
    padding-left: 30px; /* gutter size */
    background-clip: padding-box;
}

.fc-theme-standard td .fc-theme-standard th {
    border: 1px solid;
}

.table-cell-bordered td {
    border: 1px solid #e0e0e0; /* Set the border style as desired */
    color: #636363;
    font-family: 'Roboto', sans-serif;
    font-weight: 450;
    // border-bottom: 1px solid #e0e0e0;
}
.table-cell-bordered th {
    border: 1px solid #e0e0e0; /* Set the border style as desired */
    color: #636363;
}
.test {
    border-color: inherit;
    border-style: solid;
    border-width: 1px;
}
