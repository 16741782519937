@import '../../../base/bootstrap-extended/include';
@import '../../../base/components/include';

.dark-layout {
    .dx-datagrid {
        font: 20px;
        background-color: #283046;
        color: #d0d2d6 !important;
        font-weight: 500;
    }
    .dx-editor-cell .dx-texteditor .dx-texteditor-input {
        background-color: #283046;
        color: #d0d6d1;
    }
    .dx-datagrid-content .dx-data grid-table .dx-row .dx-editor-cell .dx-texteditor,
    .dx-datagrid-content .dx-datagrid-table .dx-row .dx-editor-cell .dx-texteditor-container {
        background-color: #283046;
        color: #d0d2d6;
    }
    .dx-toolbar-items-container {
        background-color: #283046;
        color: #d0d2d6;
    }
    .dx-datagrid .dx-column-lines > td {
        border-color: #626e8d;
    }
    .dx-datagrid-borders > .dx-datagrid-headers,
    .dx-datagrid-borders > .dx-datagrid-rowsview,
    .dx-datagrid-borders > .dx-datagrid-total-footer {
        border-color: #626e8d;
    }
    .dx-datagrid-borders > .dx-datagrid-filter-panel {
        border-color: #626e8d;
    }
    .dx-filterbuilder {
        border-color: #626e8d;
    }
    .dx-popup-wrapper > .dx-overlay-content {
        background: #283046 !important;
    }
    .dx-filterbuilder-group {
        background: #283046 !important;
    }

    .dx-filterbuilder .dx-filterbuilder-group .dx-filterbuilder-text {
        color: #d6d2d0;
    }
    .dx-filterbuilder .dx-filterbuilder-group .dx-icon-plus {
        color: #d0d2d6;
    }
    .dx-filterbuilder .dx-filterbuilder-group .dx-icon-remove {
        color: #d0d2d6;
    }
    .dx-treeview-node-container .dx-widget {
        color: #d0d2d6;
    }
    .dx-treeview-item .dx-treeview-item-content span {
        color: #d0d2d6;
    }
    .dx-item-content.dx-list-item-content {
        color: #d0d2d6;
    }
    .dx-icon-export-to {
        color: #d0d2d6;
    }
    .dx-icon-right {
        color: #d0d2d6;
    }
    .dx-checkbox-icon {
        border-color: #d0d2d6;
    }
    .dx-treeview ul {
        padding-left: 0px;
    }
    .dx-popup-wrapper > .dx-overlay-content {
        border-color: #626e8d;
    }
    .dx-popup-title {
        border-color: #626e8d;
    }
    .dx-toolbar-after .dx-button {
        border-color: #626e8d;
        background: #283046 !important;
        color: #d0d2d6;
    }

    .dx-button-mode-text .dx-icon {
        color: #d0d2d6;
    }
    .dx-button-content {
        color: #d0d2d6;
    }
    .dx-texteditor-input {
        background: #283046 !important;
        color: #d0d6d1 !important;
    }
    .dark-layout .dx-toolbar-after .dx-button {
        background: #283046 !important;
        color: #d0d2d6;
    }

    // selected row in dev grid table csss

    .dx-datagrid-checkbox-size.dx-checkbox-checked .dx-checkbox-icon {
        border: 1px solid #7367f0;
        background: #7367f0 !important;
        color: #d0d2d6;
    }
    .dx-popup-content .dx-datagrid-checkbox-size.dx-checkbox-checked .dx-checkbox-icon {
        border: 1px solid #7367f0;
        background: #084eda !important;
        color: #d0d2d6;
    }
    // .dx-checkbox-icon {
    //     background: #d0d2d6 !important;
    // }
    // .dx-context-menu-container-border {
    //     background: #d0d2d6 !important;
    //     color: black;
    // }
    .dx-datagrid-filter-row {
        background: #283046;
    }
    .dx-datagrid-header-panel {
        background: #283046;
    }
    // .dx-dropdowneditor-icon {
    //     background: #283046;
    // }

    .dx-dropdowneditor-icon {
        background-position: center;
        background-repeat: no-repeat;
        color: #d0d6d1;
        background-color: #283046;
        font: 18px/18px DXIcons;
        width: 18px;
        height: 100%;
        text-align: center;
    }
    .dx-rtl .dx-datagrid-rowsview .dx-selection.dx-row > td:not(.dx-focused).dx-datagrid-group-space,
    .dx-rtl .dx-datagrid-rowsview .dx-selection.dx-row:hover > td:not(.dx-focused).dx-datagrid-group-space {
        border-left-color: #d7d4f3;
    }

    .dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-lines) > td,
    .dx-datagrid-rowsview .dx-selection.dx-row:hover:not(.dx-row-lines) > td {
        border-bottom: 1px solid #d7d4f3;
        border-top: 1px solid #d7d4f3;
    }

    .dx-datagrid-rowsview .dx-selection.dx-row > td.dx-datagrid-group-space,
    .dx-datagrid-rowsview .dx-selection.dx-row:hover > td.dx-datagrid-group-space {
        border-right-color: #d7d4f3;
    }

    .dx-datagrid-rowsview .dx-selection.dx-row > td,
    .dx-datagrid-rowsview .dx-selection.dx-row:hover > td {
        background-color: rgb(133, 142, 163);
        color: #080808;
    }

    .dx-datagrid-table
        .dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row)
        > td:not(.dx-focused) {
        background-color: #443c89;
        color: #333;
    }
    // .dx-state-hover {
    //     // background-color: #443c89;
    //     // background-color: red;
    //     color: white;
    // }
    .dx-context-menu .dx-submenu {
        // background-color: #655ccf;
        background-color: #d0d2d6;

        color: rgb(41, 37, 37);
    }
    // .dx-popup-content{}
    .dx-calendar {
        background-color: #d0d2d6;
    }
    .dx-button-mode-contained {
        background-color: #d0d2d6;
    }
    .dx-widget {
        color: #d0d2d6;
    }
    .dx-treeview-item-with-checkbox .dx-treeview-item {
        color: #d0d2d6;
    }
    .dx-filter-menu.dx-menu .dx-menu-item .dx-menu-item-content {
        padding: 10px 5px 7px;
    }

    .dx-pager .dx-page-sizes .dx-selection,
    .dx-pager .dx-pages .dx-selection {
        background-color: #1a3c3f;
    }
    .dx-datagrid-filter-panel .dx-icon-filter {
        color: white;
    }
    .dx-datagrid-filter-panel .dx-datagrid-filter-panel-clear-filter,
    .dx-datagrid-filter-panel .dx-datagrid-filter-panel-text {
        color: white;
    }
    .dx-datagrid-headers {
        color: #d0d2d6;
    }

    .dx-datagrid .dx-header-filter::before {
        color: white;
    }

    .dx-group-panel-message {
        color: white;
    }

    .dx-datagrid-container.dx-filter-menu .dx-menu-item-has-icon .dx-icon,
    .dx-datagrid.dx-filter-menu .dx-menu-item-has-icon .dx-icon {
        color: white;
    }
    .dx-datagrid-headers .dx-datagrid-table .dx-row > td .dx-sort,
    .dx-datagrid-headers .dx-datagrid-table .dx-row > td .dx-sort-indicator,
    .dx-datagrid-headers .dx-datagrid-table .dx-row > td:hover .dx-datagrid-text-content {
        color: #d0d2d6;
    }

    .dx-datagrid-rowsview .dx-row.dx-group-row td {
        background-color: #283046;
        color: #d0d2d6;
    }
    .dx-datagrid .dx-header-filter::before {
        color: rgba(255, 255, 255, 0.7);
        // color: #757575;
    }
    .dx-datagrid-group-opened {
        color: #d4d4d4;
    }
    .dx-datagrid-group-closed {
        color: #d4d4d4;
    }

    .dx-datagrid .dx-row-lines > td {
        border-bottom: 1px solid #626e8d;
    }
    .dx-datagrid-summary-item {
        color: #d0d2d6;
    }

    // .dx-scrollbar-hoverable{
    //     background-color: #d0d2d6;
    // }
    .dx-datagrid-headers .dx-datagrid-table .dx-row > td {
        border-bottom: 1px solid #626e8d;
        line-height: 16px;
        font-weight: 500;
        font-size: 13px;
    }

    .dx-datagrid-drop-highlight > td {
        background: #283046 !important;
        color: white;
    }

    .dark-layout .dx-submenu .dx-icon {
        color: black !important;
    }

    .dx-submenu {
        background-color: #283046 !important;
        color: white !important;
    }

    .dx-submenu .dx-menu-item-text {
        color: white !important;
    }
    .dx-submenu .dx-menu-item-selected {
        background-color: #7885a969 !important;
    }

    // .dx-list-select-all-label{
    //     color: #080808 !important;
    // }
    .dx-datagrid-search-panel .dx-placeholder {
        color: #ffffff;
    }

    .dx-searchbox .dx-icon-search::before {
        color: #ffffff;
    }

    .dx-treeview-toggle-item-visibility {
        color: white !important;
    }

    .dx-item-content.dx-list-item-content {
        color: white !important;
    }

    .dx-scrollview-content {
        background-color: #283046 !important;
    }

    .dx-red-row {
        background: rgb(237 25 25 / 24%);
    }
    .dx-blue-row {
        background: rgb(18 176 255 / 38%);
    }

    .dx-blue-row {
        background: rgb(18 176 255 / 38%);
    }
    .dx-yellow-row {
        background: rgb(255 159 67 / 38%);
    }

    // .dx-texteditor-input {
    //     background-color: red !important;
    // }
}

//  regular light theme
.dx-datagrid-headers {
    color: #636363;
}
.dx-datagrid-checkbox-size.dx-checkbox-checked .dx-checkbox-icon {
    border: 1px solid #1a3c3f;
    background-color: #1a3c3f;
    color: white;
}
.dx-checkbox-checked .dx-checkbox-icon {
    border: 1px solid #1a3c3f;
    background-color: #1a3c3f;
    color: white;
}

.dx-rtl .dx-datagrid-rowsview .dx-selection.dx-row > td:not(.dx-focused).dx-datagrid-group-space,
.dx-rtl .dx-datagrid-rowsview .dx-selection.dx-row:hover > td:not(.dx-focused).dx-datagrid-group-space {
    border-left-color: #d7d4f3;
}

.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-lines) > td,
.dx-datagrid-rowsview .dx-selection.dx-row:hover:not(.dx-row-lines) > td {
    border-bottom: 1px solid #d7d4f3;
    border-top: 1px solid #d7d4f3;
}

.dx-datagrid-rowsview .dx-selection.dx-row > td.dx-datagrid-group-space,
.dx-datagrid-rowsview .dx-selection.dx-row:hover > td.dx-datagrid-group-space {
    border-right-color: #d7d4f3;
}

.dx-datagrid-rowsview .dx-selection.dx-row > td,
.dx-datagrid-rowsview .dx-selection.dx-row:hover > td {
    background-color: rgb(224, 241, 232);

    color: #232323;
}

.dx-datagrid-table
    .dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row)
    > td:not(.dx-focused) {
    background-color: #d7d4f3;
    color: #333;
}

.dx-datagrid {
    font: 20px;
    color: #636363 !important;
    font-weight: 500;
    // background-color: #283046;
    // color: #fff;
}

.dx-pager .dx-page-sizes .dx-selection,
.dx-pager .dx-pages .dx-selection {
    background-color: #1a3c3f;
}
.dx-datagrid-filter-panel .dx-icon-filter {
    color: #1a3c3f;
}
.dx-datagrid-filter-panel .dx-datagrid-filter-panel-clear-filter,
.dx-datagrid-filter-panel .dx-datagrid-filter-panel-text {
    color: #1a3c3f;
}
.dx-checkbox-icon {
    margin-top: 0.0825rem;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid #d8d6de;
}
.dx-button-mode-text.dx-button-default {
    color: #1a3c3f;
}
[dir='ltr'] ol,
[dir='ltr'] ul {
    padding-left: 0;
}
.dark-layout .dx-list-item .dx-icon {
    color: #d0d2d6;
}
.dark-layout .dx-item-content.dx-list-item-content {
    color: white !important;
}

// .dx-datagrid .dx-row > td {
//     padding: 4px 11px;
//     font-size: 14px;
//     line-height: 16px;
// }

.dx-datagrid .dx-datagrid-table .dx-header-row > td {
    padding-top: 12px;
    padding-bottom: 12px;
}

.dx-loadpanel-content {
    transition: all 0s ease 0s !important;
}
.dx-datagrid-focus-overlay {
    display: none;
}
.dx-datagrid-drop-highlight > td {
    background: #fff !important;
    color: rgba(0, 0, 0, 0.54);
}
.dx-red-row {
    background: #fae0e4;
    // background: rgb(237, 182, 182);
}
.dx-half-day-row {
    background: #cfbaf059;
    // background: rgb(237, 182, 182);
}
.dx-blue-row {
    background: #e2f4fd;
    // background: rgb(164, 206, 228);
}
.dx-yellow-row {
    background: #fdf7b678;
    // background: #ffd60a;

}
